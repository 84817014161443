<template>
  <b-row v-if="statistics.arrived_to_facility">
    <b-col>
      <b-card>
        <b-card-actions
            ref="loadingCard"
            no-actions
        >
          <b-tabs>
            <!-- Tab arrived -->
            <b-tab
                href="#arrived_to_facility"
                :active="isActive('arrived_to_facility')"
            >
              <template #title>
                <strong>
                  Arrived in facility
                  <b-badge variant="primary">{{ statistics.arrived_to_facility.available }}</b-badge>
                  <span class="ml-1 text-success">Done: {{ statistics.arrived_to_facility.done }}</span>
                </strong>
              </template>
              <event-component :shipmentId="this.$attrs.shipmentId" :event-hub="eventHub" type="arrived_to_facility" />
            </b-tab>
            <!-- Tab ready for last mile -->
            <b-tab
                href="#ready_for_last_mile"
                :active="isActive('ready_for_last_mile')"
            >
              <template #title>
                <strong>
                  Ready for last mile
                  <b-badge variant="primary">{{ statistics.ready_for_last_mile.available }}</b-badge>
                  <span class="ml-1 text-success">Done: {{ statistics.ready_for_last_mile.done }}</span>
                </strong>
              </template>
              <event-component :shipmentId="this.$attrs.shipmentId" :event-hub="eventHub" type="ready_for_last_mile" />
            </b-tab>
            <!-- Tab released to last mile -->
            <b-tab
                href="#released_for_last_mile"
                :active="isActive('released_for_last_mile')"
            >
              <template #title>
                <strong>
                  Released to last mile
                  <b-badge variant="primary">{{ statistics.released_for_last_mile.available }}</b-badge>
                  <span class="ml-1 text-success">Done: {{ statistics.released_for_last_mile.done }}</span>
                </strong>
              </template>
              <event-component :shipmentId="this.$attrs.shipmentId" :event-hub="eventHub" type="released_for_last_mile" />
            </b-tab>
          </b-tabs>
        </b-card-actions>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import Vue from 'vue'
import shipmentModel from '@/views/shipments/shipmentModel'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import EventComponent from '@/views/shipments/events/EventComponent.vue'

export default {
  components: {
    EventComponent,
    BBadge,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BCardActions,
  },
  data() {
    return {
      eventHub: new Vue(),
      statistics: {
        arrived: { available: 0, done: 0 },
        ready: { available: 0, done: 0 },
        released: { available: 0, done: 0 },
      },
    }
  },
  mounted() {
    this.updateStatistics()
  },
  created() {
    this.eventHub.$on('refresh-shipment-statistics', this.updateStatistics)

    // Pusher handling
    const channel = this.$pusher.subscribe(`shipment-${this.$attrs.shipmentId}`)

    // Unbind existing events
    channel.unbind_all()

    // Manifest event
    channel.bind('declaration-status-sent', () => {
      this.updateStatistics()
    })

    // Update statistics event
    channel.bind('refresh-statistics', () => {
      this.updateStatistics()
    })

    // Declaration status changes
    channel.bind('declaration-status', () => {
      this.updateStatistics()
      this.eventHub.$emit('general-declaration-update', this.$attrs.tab)
    })

    // Declaration status changes
    channel.bind('event-processing', () => {
      this.updateStatistics(false)
      this.eventHub.$emit('shipment-event-processing', this.$attrs.tab)
    })

    channel.bind('event-finished', () => {
      this.updateStatistics(false)
      this.eventHub.$emit('shipment-event-finished', this.$attrs.tab)
    })

    this.getShipment()
  },
  methods: {
    getShipment() {
      const cachedData = shipmentModel().getCachedShipment(this.$attrs.shipmentId)
      if (cachedData === null) {
        shipmentModel().getShipment(this.$attrs.shipmentId, this)
          .then(response => {
            shipmentModel().cacheShipment(response.data)
            this.updatePageTitle(response.data.document_number)
            this.updateCompanyInfo(response.data.company)
          })
      } else {
        this.updatePageTitle(cachedData.document_number)
        this.updateCompanyInfo(cachedData.company)
      }
    },
    updatePageTitle(documentNumber) {
      this.$route.meta.pageTitle = `Shipment events (${documentNumber})`
      this.$globalEvent.$emit('update-page-title', this.$route.meta.pageTitle)
    },
    updateCompanyInfo(company) {
      if (this.$profile().data.user.companies.length > 1) {
        this.$route.meta.company = company
        this.$globalEvent.$emit('update-page-company', company)
      }
    },
    isActive(tab) {
      return this.$attrs.tab === tab
    },
    updateStatistics(showLoader = true) {
      if (this.$refs.loadingCard && showLoader) {
        this.$refs.loadingCard.showLoading = true
      }

      return this.$http.get(`/v1/api/companies/${this.$activeCompany().data.company.id}/shipments/${this.$attrs.shipmentId}/events/statistics`)
        .then(response => {
          if (this.$refs.loadingCard && showLoader) {
            this.$refs.loadingCard.showLoading = false
          }
          const statistics = {}
          statistics.arrived_to_facility = {
            available: response.data.data[0].available,
            done: response.data.data[0].done,
          }
          statistics.ready_for_last_mile = {
            available: response.data.data[1].available,
            done: response.data.data[1].done,
          }
          statistics.released_for_last_mile = {
            available: response.data.data[2].available,
            done: response.data.data[2].done,
          }
          this.statistics = statistics
        })
    },
  },
}
</script>

<style scoped>

</style>
