<template>
  <div>
    <edit-parcel-items
      v-if="selectedParcel"
      id="edit-parcel-items"
      :declaration="selectedDeclaration"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :shipment="shipment"
      :readonly="!showEdit"
    />
    <b-row>
      <b-col cols="2">
        <b-form-group
          label="Buyer country:"
          label-for="buyer-country"
        >
          <b-form-select
            id="buyer-country"
            v-model="buyerCountry"
            label="Buyer country"
            :options="buyerCountryOptions"
            @change="applyCountryFilter"
          />
        </b-form-group>
      </b-col>
      <b-col cols="10">
        <div class="d-flex justify-content-between">
          <b-form-group
            class="full-width"
            label="Container codes:"
            label-for="container-codes"
          >
            <b-form-tags
              id="container-codes"
              v-model="filterContainerCodes"
              label="Container codes"
              :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
              separator=" ,;"
              placeholder="Enter new container codes separated by space, comma or semicolon"
              no-add-on-enter
              add-on-change
              clearable
              @input="resetSelections"
            />
          </b-form-group>
          <div>
            <b-button
              v-if="filtersApplied"
              id="filter-reset"
              variant="outline-secondary"
              class="mt-2 ml-2"
              @click="resetFilters"
            >
              Reset
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-table
      :items="flatTree"
      responsive
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      no-local-sorting
      show-empty
      :busy="dataLoading"
      empty-text="No matching records found"
      class="mb-0 parcels-tree-table"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>{{ dataLoadingMessage }}...</strong>
        </div>
      </template>

      <template #head(container)="">
        <div
          v-if="canEdit"
          style="white-space: nowrap"
        >
          <b-form-checkbox
            v-if="!disableCheckbox"
            v-model="selectedAll"
            class="float-left"
            @change="onChangeAll"
          />
          Container
          (<span
            v-if="selectedContainers.length > 0"
            class="selected-items"
          >{{ selectedContainers.length }} selected {{ selectedContainersKg }} kg / </span>{{ $attrs.containers.length }})
        </div>
      </template>

      <template #cell(container)="data">
        <div
          v-if="typeof data.item.tracking_number === 'undefined' && data.item.container"
          style="white-space: nowrap"
        >
          <b-form-checkbox
            v-if="!disableCheckbox && canEdit"
            v-model="selectedContainers"
            :value="data.item.container"
            class="float-left"
            @change="onChangeContainer(data.item.container)"
          />
          <b-link
            v-b-tooltip.hover.right="'Parcels in shown status / total container parcels'"
            href="#"
            class="containerLink"
            @click="openCloseParcels(data.item.container)"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
              :class="{
                'chevron-icon': true,
                'expanded': isOpened(data.item.container)
              }"
            />
            <span v-if="data.item.container !== '(bulk)'">{{ data.item.container }}</span>
            <span
              v-if="data.item.container === '(bulk)'"
              style="font-style: italic;"
            >(bulk)</span>
            <span> ({{ data.item.parcel_count }}/{{ data.item.total_parcel_count }})</span>
          </b-link>
          <copy-icon
            :text="data.item.container"
            tooltip-text="Copy container code to clipboard"
            tooltip-success-text="Container code copied to clipboard"
          />
        </div>
      </template>

      <template #cell(buyer_country)="data">
        {{ data.item.buyer_country }}
      </template>
      <template #cell(tracking_number)="data">
        <div
          v-if="data.item.loading"
          class="text-center my-2"
        >
          <b-spinner class="align-middle mr-1" />
          <strong>Loading...</strong>
        </div>
        <div
          v-if="data.item.tracking_number"
          style="white-space: nowrap"
        >
          <b-form-checkbox
            v-if="!disableCheckbox && canEdit"
            v-model="selectedParcels"
            :value="data.item.tracking_number"
            class="float-left"
            @change="onChangeParcel(data.item.container)"
          />
          {{ data.item.tracking_number }}
        </div>
      </template>

      <template #cell(descriptions)="data">
        <div>
          {{ data.item.items.map(function (parcelItem) {return parcelItem.description}).join(', ') }}
        </div>
      </template>

      <template #cell(hs_codes)="data">
        <div>
          {{ data.item.items.map(function (parcelItem) {return parcelItem.hs_code}).join(', ') }}
        </div>
      </template>

      <template #cell(value)="data">
        <div
          v-if="typeof data.item.tracking_number !== 'undefined'"
          class="text-right"
        >
          {{ data.item.items.reduce(function (sum, parcelItem) {return sum + parseFloat(parcelItem.value)}, 0) | formatNumber(2) }}
        </div>
      </template>

      <template #cell(actions)="data">
        <div v-if="data.item.tracking_number">
          <b-button-group>
            <view-parcel-button
              v-if="!showEdit"
              :declaration="data.item.low_value_declaration"
              :parcel="data.item"
              :event-hub="eventHub"
            />
            <b-button
              v-if="showEdit"
              v-b-modal.edit-parcel-items
              type="button"
              variant="warning"
              @click="editDeclaration(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <download-pdf-button
              v-if="!showEdit"
              :parcel="data.item"
              :shipment="shipment"
            />
          </b-button-group>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BLink,
  BTable,
  BSpinner,
  BRow,
  BCol,
  BFormSelect,
  BFormGroup,
  BFormTags,
  BButton,
  BButtonGroup,
  VBTooltip,
} from 'bootstrap-vue'

import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import Vue from 'vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import DownloadPdfButton from '@/views/shipments/declarations/components/DownloadPdfButton.vue'
import CopyIcon from '@/views/components/CopyIcon.vue'

export default {
  components: {
    CopyIcon,
    DownloadPdfButton,
    BButtonGroup,
    EditParcelItems,
    BFormSelect,
    BCol,
    BRow,
    BFormCheckbox,
    BFormGroup,
    BLink,
    BTable,
    BSpinner,
    BFormTags,
    BButton,
    ViewParcelButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    disableCheckbox: Boolean,
    dataLoading: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    dataLoadingMessage: {
      type: String,
      default: 'Loading',
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
    eventHub: {
      default: () => new Vue(),
    },
  },
  data() {
    return {
      loading: {},
      parcels: {},
      fullParcelsData: [],
      selectedDeclaration: null,
      selectedParcel: null,
      buyerCountry: '(All)',
      sortBy: 'container',
      isSortDirDesc: false,
      selectedAll: false,
      selectedContainers: [],
      selectedParcels: [],
      openContainers: [],
      filterContainerCodes: [],
      filterContainerCodesByCountry: [],
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    filtersApplied() {
      return this.filterContainerCodes.length > 0 || this.buyerCountry !== '(All)'
    },
    selectedContainersKg() {
      const containers = this.containersForView
      let totalWeight = 0
      this.selectedContainers.forEach(containerCode => {
        if (containers[containerCode]) {
          totalWeight += containers[containerCode].total_parcel_weight
        }
      })
      return totalWeight.toFixed(3)
    },
    buyerCountryOptions() {
      const { containersForView } = this
      const containers = Object.keys(containersForView)

      let countries = ['(All)']
      containers.forEach(containerKey => {
        const container = containersForView[containerKey]
        if (container.buyer_country) {
          countries = [...new Set([...countries, ...container.buyer_country.split(', ')])]
        }
      })
      return countries
    },
    fields() {
      let fields = [
        { key: 'container', label: 'Container', sortable: true },
        { key: 'buyer_country', label: 'Buyer country' },
        { key: 'tracking_number', label: 'Tracking code', sortable: this.openContainers.length > 0 },
        { key: 'descriptions', label: 'Description' },
        { key: 'hs_codes', label: 'HS code' },
        { key: 'value', label: 'Value' },
        { key: 'actions', label: 'Actions' },
      ]
      if (this.$attrs.clearance) {
        fields = [
          { key: 'container', label: 'Container', sortable: true },
          { key: 'tracking_number', label: 'Tracking code', sortable: this.openContainers.length > 0 },
          { key: 'lrn', label: 'LRN' },
          { key: 'mrn', label: 'MRN' },
          { key: 'actions', label: 'Actions' },
        ]
      }
      return fields
    },
    flatTree() {
      const { containersForView } = this
      const flatTree = []

      const containers = Object.keys(containersForView)

      containers.forEach(containerKey => {
        const container = containersForView[containerKey]
        if (this.buyerCountry !== '(All)' && !container.buyer_country.includes(this.buyerCountry)) {
          return
        }
        const flatContainer = {
          id: container.code,
          container: container.code || '(bulk)',
          buyer_country: container.buyer_country,
          parcel_count: container.parcel_count,
          total_parcel_count: container.total_parcel_count,
          items: [],
        }
        if (!this.filterContainerCodes.length || this.filterContainerCodes.includes(container.code)) {
          flatTree.push(flatContainer)
        }

        const openedIndex = this.openContainers.indexOf(container.code)

        if (openedIndex === -1) {
          return
        }

        const parcels = this.parcels[container.code]

        if (typeof parcels === 'undefined') {
          flatTree.push({
            loading: true,
            items: [],
          })
          if (this.loading[container.code] !== true) {
            this.loading[container.code] = true
            this.requestParcels(container.code)
          }
          return
        }

        for (let i = 0; i < parcels.length; i += 1) {
          const parcel = parcels[i]
          if (this.buyerCountry === '(All)' || parcel.buyer.country === this.buyerCountry) {
            flatTree.push({
              ...parcel,
              buyer_country: parcel.buyer.country,
              container: container.code,
              lrn: parcel.low_value_declaration?.lrn,
              mrn: parcel.low_value_declaration?.mrn,
            })
          }
        }
      })

      return flatTree
    },
    containersForView() {
      const { containers } = this.$attrs
      const containersForView = {}

      for (let i = 0; i < containers.length; i += 1) {
        const container = containers[i]

        containersForView[container.code] = {
          code: container.code,
          buyer_country: container.buyer_country,
          parcel_count: container.parcel_count,
          total_parcel_count: container.total_parcel_count,
          total_parcel_weight: container.total_parcel_weight,
          parcels: null,
        }
      }

      return containersForView
    },
  },
  watch: {
    filters() {
      this.parcels = {}
    },
    sortBy() {
      if (this.sortBy !== 'container') {
        this.openContainers.forEach(container => {
          this.requestParcels(container)
        })
      }
    },
    isSortDirDesc() {
      if (this.sortBy === 'container') {
        this.$emit('sortDesc', this.isSortDirDesc)
      } else {
        this.openContainers.forEach(container => {
          this.requestParcels(container)
        })
      }
    },
  },
  created() {
    this.eventHub.$on('update-parcel-items', () => {
      this.parcels = {}
      this.selectedParcel = null
      this.openContainers = []
    })
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.eventHub.$on('clear-selections', this.tableClearSelections)
  },
  methods: {
    editDeclaration(data) {
      this.selectedParcel = data
    },
    resetFilters() {
      this.resetSelections()
      this.buyerCountry = '(All)'
      this.filterContainerCodes = []
      this.filterContainerCodesByCountry = []
    },
    tableClearSelections() {
      this.selectedAll = true
      this.selectedAll = false
      this.parcels = {}
      this.openContainers = []
      this.selectedContainers = []
      this.filterContainerCodesByCountry = []
    },
    resetSelections() {
      this.tableClearSelections()
      this.propagateSelection()
    },
    applyCountryFilter() {
      this.resetSelections()

      if (this.buyerCountry !== '(All)' && this.buyerCountryOptions.length > 1) {
        Object.keys(this.containersForView).forEach(containerKey => {
          const container = this.containersForView[containerKey]
          if (container.buyer_country === this.buyerCountry) {
            this.filterContainerCodesByCountry.push(containerKey)
          }
        })
      }
    },
    requestParcels(containerCode) {
      this.$http.get(this.searchQuery(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/${this.declarationType}/containers/${containerCode}/parcels`), {
        params: {
          filter: this.$attrs.filter,
          sortBy: this.sortBy !== 'container' ? this.sortBy : null,
          sortDesc: this.isSortDirDesc,
        },
      })
        .then(response => {
          this.loading[containerCode] = false
          const temp = this.parcels
          temp[containerCode] = response.data.data
          this.parcels = null
          this.parcels = temp
          this.fullParcelsData.push(temp)
          this.$emit('parcels', {
            fullParcelsData: this.fullParcelsData,
          })
          this.applyChangeContainer(containerCode)
        })
    },
    viewParcel(declaration, parcel) {
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    openCloseParcels(containerCode) {
      const openedIndex = this.openContainers.indexOf(containerCode)
      if (openedIndex === -1) {
        this.openContainers.push(containerCode)
      } else {
        this.openContainers.splice(openedIndex, 1)
      }
    },
    onChangeAll() {
      this.applyChangeAll()
      this.propagateSelection()
    },
    onChangeContainer(containerCode) {
      this.applyChangeContainer(containerCode)
      this.selectAllByChildren()
      this.propagateSelection()
    },
    onChangeParcel(containerCode) {
      this.applyChangeParcel(containerCode)
      this.selectAllByChildren()
      this.propagateSelection()
    },
    applyChangeAll() {
      const containers = this.containersForView

      Object.keys(containers).forEach(containerCode => {
        const containerIndex = this.selectedContainers.indexOf(containerCode)

        if (this.selectedAll) {
          if (
            (!this.$data.filterContainerCodes.length && !this.filterContainerCodesByCountry.length)
            || this.$data.filterContainerCodes.includes(containerCode)
            || this.filterContainerCodesByCountry.includes(containerCode)
          ) {
            // only missing containers should be added to the list
            if (containerIndex === -1) {
              this.selectedContainers.push(containerCode)
            }
          }
        } else if (containerIndex !== -1) {
          this.selectedContainers.splice(containerIndex, 1)
        }
        this.applyChangeContainer(containerCode, false)
      })
    },
    applyChangeContainer(containerCode) {
      const parcels = this.parcels[containerCode]

      if (typeof parcels === 'undefined') {
        return
      }

      Object.keys(parcels).forEach(parcelKey => {
        const trackingNumber = parcels[parcelKey].tracking_number
        const parcelIndex = this.selectedParcels.indexOf(trackingNumber)

        if (this.selectedContainers.indexOf(containerCode) !== -1) {
          if (parcelIndex === -1) {
            this.selectedParcels.push(trackingNumber)
          }
        } else if (parcelIndex !== -1) {
          this.selectedParcels.splice(parcelIndex, 1)
        }
      })
    },
    applyChangeParcel(containerCode) {
      const parcels = this.parcels[containerCode]

      let allContainerParcelsSelected = true

      Object.keys(parcels).every(parcelKey => {
        const oneTrackingNumber = parcels[parcelKey].tracking_number
        const parcelIndex = this.selectedParcels.indexOf(oneTrackingNumber)

        if (parcelIndex === -1) {
          allContainerParcelsSelected = false
          return false
        }

        return true
      })

      const selectedContainerIndex = this.selectedContainers.indexOf(containerCode)
      if (allContainerParcelsSelected) {
        if (selectedContainerIndex === -1) {
          this.selectedContainers.push(containerCode)
        }
      } else if (selectedContainerIndex !== -1) {
        this.selectedContainers.splice(selectedContainerIndex, 1)
      }
    },
    selectAllByChildren() {
      const containers = this.containersForView

      let allSelected = true

      Object.keys(containers).every(containerCode2 => {
        const containerIndex2 = this.selectedContainers.indexOf(containerCode2)
        if (containerIndex2 === -1) {
          allSelected = false
          return false
        }
        return true
      })

      this.selectedAll = allSelected
    },
    propagateSelection() {
      let allSelected = this.selectedAll
      if (
        (this.buyerCountry !== '(All)' && this.buyerCountryOptions.length > 2)
        || this.$data.filterContainerCodes.length > 0
      ) {
        // if there is only 1 option after "(All)", then all is selected in both cases
        // or user has selected some containers in the filter
        allSelected = false
      }

      this.$emit('selection', {
        selectedAll: allSelected,
        selectedContainers: this.selectedContainers,
        selectedParcels: this.selectedParcels,
      })
    },
    searchQuery(url) {
      // Apply filters
      if (Object.keys(this.filters).length > 0) {
        const q = []
        Object.keys(this.filters).forEach(key => {
          if (this.filters[key] !== null) {
            q.push(`${key}=${this.filters[key]}`)
          }
        })
        url += `?${q.join('&')}`
      }

      return url
    },
    isOpened(containerCode) {
      return this.openContainers.indexOf(containerCode) !== -1
    },
  },
}
</script>
<style>
  #buyer-country {
    height: 2.4rem;
  }
  #filter-reset {
    padding-top: .63rem;
    padding-bottom: .63rem;
  }
  .chevron-icon {
    transition: all 0.3s;
  }
  .chevron-icon.expanded {
    transform: rotate(90deg);
  }
</style>
