<template>
  <b-link
    v-b-tooltip.hover.right="{ variant: tooltipStyle }"
    href="#"
    :title="tooltipTextView"
    :class="{'text-success': copySuccess}"
    @click="copyToClipboard(text)"
  >
    <feather-icon
      icon="CopyIcon"
      size="16"
      class="ml-1 mr-0 mr-sm-50"
    />
  </b-link>
</template>

<script>
import { BLink, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    tooltipSuccessText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copySuccess: false,
      tooltipStyle: '',
    }
  },
  computed: {
    tooltipTextView() {
      if (!this.tooltipStyle) {
        return this.tooltipText
      }
      return this.tooltipSuccessText
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copySuccess = true
        this.tooltipStyle = 'success'
        setTimeout(() => {
          this.copySuccess = false
          this.tooltipStyle = ''
        }, 2000)
      })
    },
  },
}
</script>

<style>
.tooltip.tooltip-success {
  background-color: #28a745 !important; /* Success green */
  color: #fff;
}
</style>
